import React from "react"
import { Router } from "@reach/router"
import { Link } from 'react-router-dom'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero1 from "../components/Hero/Hero1"
import Recognition from "../components/Recognition"
import HyperAutomation from "../components/HyperAutomation"
import Container from "../components/ui/Container"
import Card1 from "../components/Card/Card1"
import { StaticImage } from "gatsby-plugin-image"
import Cta1 from "../components/Cta/Cta1"
import { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
   
    
  <Container>
        
    <div className="grid lg:grid-cols-2 sm:grid-cols-2  gap-14 mt-21 place-items-center mb-[7rem]">
    <div className="items-end">
    <span className="mtext">Mortage</span>
            <h1 className="blogft">
               
            <span className="">We</span> scratch, build and play together
            </h1>
            <p className="text-gray-700 text-base mtr1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
            </p>
            <button className="bg-transparent bg-red-500 text-white-700 font-semibold hover:text-white blogbtn2 border border-blue-500 hover:border-transparent rounded">
              Read More
            </button>
          </div>
          <div className="items-end ">
            <img
              className="blogimg "
              src="/images/mask.png"
              alt="Sunset in the mountains"
            />
          </div>
         
        
        </div>
        </Container>
<br/>


 
<Container>
<h1 className="hm10 mt-[7rem]">Committed to making Hyperautomation work for you</h1><br/>
<p className="hm11 ">Enabling companies save hours of drudgery everyday and build resilient operations</p>
<div className="grid lg:grid-cols-5 grid-cols-2 md:grid-cols-3 gap-4  bgp">
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p1.png"/>

    
  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p2.png"/>

   

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p3.png"/>

   

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p4.png"/>

   

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p5.png"/>

 

  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p6.png"/>

 
  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p7.png"/>


  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p8.png"/>

  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6  bg-white max-w-sm">
    <img className="img1" src="/images/p9.png"/>

 
  
  </div>
</div>
<div className="flex justify-center">
  <div className="block p-6 bg-white max-w-sm">
    <img className="img1" src="/images/p10.png"/>

  
  </div>
</div>
</div>
    <div className="mt-[7rem]">
<Swiper navigation={true} modules={[Navigation]} className="mySwiper">
  
       
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">
  <div className="w-full md:w-1/3 bg-white grid ">
      <img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" className="rounded-xl" />
</div>
      <div className="w-full md:w-2/3 p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic </h3>
              <h3 className="hm27"> Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">
  <div className="w-full md:w-1/3 bg-white grid ">
      <img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" className="rounded-xl" />
</div>
      <div className="w-full md:w-2/3 p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic and Wonderful Bahamas</h3>
              <h3 className="hm27">The Majestic and Wonderful Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
  <SwiperSlide>
  <div className="flex flex-col  ">
<div
  className=" flex rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl  border border-white bg-white">
  <div className="w-full md:w-1/3 bg-white grid ">
      <img src="https://images.pexels.com/photos/4381392/pexels-photo-4381392.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="tailwind logo" className="rounded-xl" />
</div>
      <div className="w-full md:w-2/3  p-[1rem]">
          
      
<p className="">The best kept secret of The Bahamas is the country’s sheersize and diversity. With 16 major islands, The Bahamas is an unmatched destination</p>
              <h3 className="hm26">The Majestic and Wonderful Bahamas</h3>
              <h3 className="hm27">The Majestic and Wonderful Bahamas</h3>
      </div>
  </div>
</div>

  </SwiperSlide>
</Swiper>
</div>



</Container>




  


<div className="hm19 p-[1rem] mt-[3rem] mb-[7rem]">
<h1 className="hm10 mt-[3rem] mb-3rem">Transform every corner of your organization
</h1>
<p className="hm11 ">Deliver seamless customer journeys that drive growth, increase productivity, and deepen customer relationships.
</p>
<div className="grid lg:grid-cols-3 md:grid-cols-2 webv1 hm3 lg:mt-[3rem]  gap-4 place-items-center pad1rr">
  

<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>
<div className="hm35 bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
    <a href="#">
        <img className="rounded-t-lg" src="/images/tech.png" alt=""/>
    </a>
    <div className="p-5">
        <a href="#">
            <h5 className="hm36">Business Unit</h5>
        </a>
        <p className="hm32">
        View Benefits &nbsp; <i className="fa fa-long-arrow-right"></i>
           </p>
    </div>
</div>



</div>

</div>
  
  </Layout>
)

export default IndexPage
